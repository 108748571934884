import * as React from "react"
import { navigate } from 'gatsby-link'
import { graphql, useStaticQuery } from "gatsby"

import Layout from "components/layout/layout"
import Seo from "components/layout/seo"

import ImgBackground from "components/heroes/ImgBackground"
import TitleBox from "components/titles/Titlebox"

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const Contact = () => {

  // using static header image, load here
  const data = useStaticQuery(
    graphql`
    query {
      heroImage: file(relativePath: { eq: "backgrounds/renewables-sunset.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1200
          )
        }
      }
    }
  `
  )

  const [state, setState] = React.useState({})

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }


  return (
    <Layout>
      <Seo title="Contact Us" />

      {/* Title banner */}
      <ImgBackground image={data.heroImage}>
        <div className="container m-auto py-10 lg:pb-0 md:pt-40 lg:pt-60 lg:flex">
          <TitleBox
            title="Would you like to speak with one of our team?"
            caption="Contact Us"
          >
          </TitleBox>
        </div>
      </ImgBackground>



      {/* Content Area */}
      <section className="page--content py-32">
        <div className="container lg:flex">
          <div className="w-full mb-16 lg:w-2/5 lg:order-2 prose prose-sm">

            <h3 className="mb-2">London - Solar PV Head Office</h3>
            <p>4 Albemarle Street, Mayfair, London. W1S 4GA<br />
            Tel: +44 (0)20 3988 3399<br />
            london@pacificgreen.tv</p>

            <h3 className="mb-2">Madrid</h3>
            <p>Paseo de la Castellana 18, 7th Floor. 28046 Madrid. Spain<br />
            madrid@pacificgreen.tv</p>

            <h3 className="my-2">Vancouver</h3>
            <p>204-275 Fell Avenue, North Vancouver, BC V7P 3R5 Canada<br />
            Tel: +1 604 973 1038<br />
            vancouver@pacificgreen.tv</p>

            <h3 className="my-2">Shanghai</h3>
            <p>No.158 Jiyang Road, New Bund World Trade Center, B Block 20F, Shanghai, China<br />
            shanghai@pacificgreen.tv</p>

            <h3 className="my-2">For all other global enquiries</h3>
            <p><strong>Pacific Green Technologies Inc.</strong><br />
            8 The Green, Suite #10212. Dover. Delaware 19901. USA<br />
            Tel: (302) 601-4659<br />
            info@pacificgreen.tv</p>

          </div>
          <div className="w-full lg:w-3/5 lg:pr-16">
            <div className="bg-primary-50 rounded p-16">
              <h3 className="mb-4">Contact request</h3>
              <form
                name="contact"
                method="post"
                action="/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
                className="w-full"
              >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="contact" />

                <div hidden><label>Not for humans: <input name="bot-field" onChange={handleChange} /></label></div>


                <div className="grid grid-cols-1 gap-6">

                  <label className="block">
                    <span className="text-gray-700">Full name</span>
                    <input type="text" name="name" onChange={handleChange} className="
                    mt-1
                    block
                    w-full
                    rounded-md
                    border-gray-300
                    shadow-sm
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                  " placeholder="" />
                  </label>

                  <label className="block">
                    <span className="text-gray-700">Email address</span>
                    <input type="email" name="email" onChange={handleChange} className="
                    mt-1
                    block
                    w-full
                    rounded-md
                    border-gray-300
                    shadow-sm
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                  " placeholder="john@example.com" />
                  </label>

                  <label className="block">
                    <span className="text-gray-700">Company Name</span>
                    <input type="text" name="company" onChange={handleChange} className="
                    mt-1
                    block
                    w-full
                    rounded-md
                    border-gray-300
                    shadow-sm
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                  " placeholder="" />
                  </label>

                  <label className="block">
                    <span className="text-gray-700">Interested in</span>
                    <select name="interests" onChange={handleChange} className="
                    block
                    w-full
                    mt-1
                    rounded-md
                    border-gray-300
                    shadow-sm
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                  ">
                      <option>General Interest</option>
                      <option>Solar Canopies</option>
                      <option>EV Charging</option>
                      <option>Utility</option>
                      <option>Rooftop</option>

                    </select>
                  </label>

                  <label className="block">
                    <span className="text-gray-700">Additional details</span>
                    <textarea name="message" onChange={handleChange} className="
                    mt-1
                    block
                    w-full
                    rounded-md
                    border-gray-300
                    shadow-sm
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                  " rows="3"></textarea>
                  </label>

                </div>
                <div className="my-4">
                  <button type="submit" className="btn-primary px-8">Send</button>
                </div>

              </form>
            </div>
          </div>

        </div>
      </section>


    </Layout>
  )
}

export default Contact
